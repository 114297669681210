import React, { Component } from 'react'
import dynamic from 'next/dynamic'
// import { API_TARGET } from '../../config'
import Link from 'next/link'
// import piboxBoard from '../../assets/pibox-hero-white-with-pi-white-small.png'
// import repoBuilderScreenshot from '../../assets/home-repo-builder.png'
// import backupsScreenshot from '../../assets/backups-screenshot.png'
// import containerExecScreenshot from '../../assets/container-exec-screenshot.png'
// import clustersList from '../../assets/clusters-list.png'
// import preOrderKickstarter from '../../assets/pre-order-kickstarter.png'

const YouTube = dynamic(() => import('../../components/YouTube'), { ssr: false })

const appScreenshots = [
  {
    title: ' ',
    titleWidth: 480,
    element: (width = 480, height = 270, slideMarginTop = 200) => (
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width,
          height,
        }}
      >
        <div style={{ marginTop: slideMarginTop, width, height }}>
          <YouTube embedId="g5XjXEI2xW8" width={width} height={height} />
        </div>
      </div>
    ),
  },
  // {
  //   img: piboxBoard,
  //   title: 'ready-to-ship, custom hardware designed for home-hosting',
  // },
  // {
  //   img: backupsScreenshot,
  //   title: 'Backup your data to KubeSail and restore any time',
  // },
  // {
  //   img: repoBuilderScreenshot,
  //   title: 'Build projects from GitHub on your own systems',
  // },
  // {
  //   img: containerExecScreenshot,
  //   title: 'Log in to and manage any app or server',
  // },
  // {
  //   img: clustersList,
  //   title: 'Manage your servers, invite friends, and more!',
  // },
]

export default class Hero extends Component {
  state = {
    slideWidth: 480,
    slideHeight: 270,
    slideMarginTop: 200,
    paused: true,
    examples: [
      <>
        Run a server in your home or office that runs websites &amp; apps, saves money, and respects
        your privacy.
      </>,
      <>
        Everything you need to run Google alternatives like{' '}
        <Link
          className="example"
          href="/templates/[[...params]]"
          as="/templates/erulabs/photoprism"
          prefetch={false}
        >
          PhotoPrism
        </Link>{' '}
        at home
      </>,
      <>
        Everything you need to host game-servers like{' '}
        <Link
          className="example"
          href="/templates/[[...params]]"
          as="/templates/erulabs/minecraft"
          prefetch={false}
        >
          Minecraft
        </Link>{' '}
        for the squad
      </>,
      <>
        Everything you need to build an epic media-server at home with{' '}
        <Link
          className="example"
          href="/templates/[[...params]]"
          as="/templates/erulabs/plex"
          prefetch={false}
        >
          Plex
        </Link>
      </>,
      <>
        Everything you need to secure the company chat with{' '}
        <Link
          className="example"
          href="/templates/[[...params]]"
          as="/templates/erulabs/mattermost"
          prefetch={false}
        >
          Mattermost
        </Link>
      </>,
    ],
    example: 0,
  }

  componentDidMount() {
    this._mounted = true
    // setInterval(() => {
    //   let newExample = this.state.example + 1
    //   if (newExample > this.state.examples.length - 1) {
    //     newExample = 0
    //   }
    //   this.setState({ example: newExample })
    // }, 12000)

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.resizer)
      this.resizer()
    }
  }

  componentWillUnmount() {
    this._mounted = false
    window.removeEventListener('resize', this.resizer)
  }

  resizer = () => {
    if (!this._mounted) return
    if (window.innerWidth > 1300) {
      this.setState({ slideWidth: 800, slideHeight: 450, slideMarginTop: 50 })
    } else {
      this.setState({ slideWidth: 480, slideHeight: 270, slideMarginTop: 200 })
    }
  }

  render() {
    appScreenshots[0].titleWidth = this.state.slideWidth
    return (
      <div className="Home-Hero">
        <div className="hero">
          <h1>Host Apps From Home</h1>
          <h4>{this.state.examples[this.state.example]}</h4>
          <div className="list-of-features">
            <ul>
              <li>
                <a
                  rel="noreferrer nofollow"
                  target="_blank"
                  title="PiBox by KubeSail - Custom hardware for self-hosting"
                  href="https://pibox.io"
                >
                  Use our custom hardware
                </a>
                &nbsp;or bring your own computer
              </li>

              <li>Open and hackable, but beginner friendly</li>
              <li>Backups, remote access, mobile apps, support, tutorials &amp; more</li>
            </ul>
          </div>
          <div className="call-to-action">
            <a href="/login" className="call-to-action">
              <button className="theme-button">Start for free</button>
            </a>
          </div>
        </div>
        <div
          className="demo-image"
          onClick={() => {
            const paused = !this.state.paused
            this.setState({ paused })
          }}
        >
          {appScreenshots.map((screenshot, i) => {
            return (
              <div
                key={screenshot.title || screenshot.img.src}
                index={i}
                style={{ height: '100%', backgroundColor: screenshot.backgroundColor }}
              >
                {screenshot.img ? (
                  <div
                    className="demo-slide"
                    style={{
                      backgroundImage: screenshot.img && `url(${screenshot.img.src})`,
                      backgroundSize: screenshot.img?.width,
                      backgroundColor: screenshot.backgroundColor,
                    }}
                    title={screenshot.title}
                  ></div>
                ) : screenshot.element ? (
                  screenshot.element(
                    this.state.slideWidth,
                    this.state.slideHeight,
                    this.state.slideMarginTop
                  )
                ) : null}
                <h4
                  style={{
                    color: screenshot.color,
                    backgroundColor: screenshot.backgroundColor,
                    maxWidth: screenshot.titleWidth,
                    marginTop: '10px',
                  }}
                >
                  {screenshot.title}
                </h4>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
